/* The following block can be included in a custom.scss */

/* https://getbootstrap.com/docs/4.4/getting-started/theming/ */
/* Refer to ./node_modules/bootstrap/scss/_variables.scss  for what can be modified */

//$body-bg: #000;
//$body-color: #111;



@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


/* make the customizations */
$theme-colors: (
        "info": tomato,
        "danger": teal
);


$white: #fff;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #373a3c !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #2780e3 !default;
$indigo:  #6610f2 !default;
$purple:  #613d7c !default;
$pink:    #e83e8c !default;
$red:     #ff0039 !default;
$orange:  #f0ad4e !default;
$yellow:  #ff7518 !default;
$green:   #3fb618 !default;
$teal:    #20c997 !default;
$cyan:    #9954bb !default;

$text-title-color: #4b6a78;
$text-highlight-color: #f7f2a3;
$text-list-box-color: #e7f1f6;
$text-subheading-color: #333333;
$text-body-color: #4d4d4d;

//$navbar-dark-color:                 rgba($blue, .5);
//$navbar-light-color:                rgba($blue, .5) !default;


//$body-color:    $gray-800 !default;

//$nav-tabs-link-active-color:        $blue;
//$nav-link-disabled-color:           $blue;
//$nav-tabs-link-active-bg:           $blue;
//$nav-tabs-link-disabled-bg:         $blue;
//$nav-tabs-link-active-color:  $blue;
//$nav-tabs-link-active-bg: $blue;

// This changes the navbar link color (provided that navbar-light is used)
// e.g. HOME, LOGOUT, ABOUT, CONTACT
// found in _navbar.scss
$navbar-light-color: $blue;
$navbar-light-hover-color: $white;


//$navbar-light-disabled-color: $pink;
//$navbar-dark-disabled-color: $pink;
//$navbar-link-disabled-color: $pink;

.navbar {
  background-color: $white;
}

.navbar-nav {
  .nav-link {
    color: $navbar-light-color;
    @include hover-focus() {
      background-color: $navbar-light-color;
    }
  }

}

.footer {
  color: $black;
}

// Cosmo theme
@import "./bootstrap/cosmo/variables";




/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";


              // Not sure why, but some stuff seems to need to go after bootstrap imports


.App {
  background-image: url('../images/WorkACCESS BKGRND.png');
  background-repeat: no-repeat;
  background-size: cover; //contain
  text-align: center;

}

.dropdown-menu {
  right: 0;
  left: auto;
}

.btn-home {
  background-color: $white;
  color: $blue;
  border: $blue;
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 5px 5px 1px 1px $blue;
}

.btn-xxl {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}

//.form-check.input[type="checkbox"] {
//
//  color: $red;
//  background-color: $white;
//
//}


//.task {
//  background-color: $yellow;
//}
//
//h3.task  {
//  background-color: $blue;
//}


//$text-title-color: #4b6a78;
//$text-highlight-color: #f7f2a3;
//$text-list-box-color: #e7f1f6;
//$text-subheading-color: #333333;
//$text-body-color: #4d4d4d;

.modal-90 {
  width: 90%;
}

.results-base {
  background-color: $white;
  margin-left: 40px;
  margin-right: 40px;
  color: $text-title-color;
}

.matches {
  text-align: left;
  margin: 20px;
}

.task h3 {
  display: table;
  padding: 5px;
  background-color: $text-highlight-color;
  color: $text-subheading-color;
}

.strategy  {
  padding: 5px;
  background-color: $text-highlight-color;
  color: $text-subheading-color;
}

.gap {
  background-color: $text-list-box-color;
}

.gap h4 {
  color: $text-subheading-color;
  font-weight: bold;
}

.gap ul {
  //display: flex;
  //flex-direction: column;
  //align-items: flex-start;
  //align-content: flex-start;
  //flex-wrap: wrap;
  list-style: none;

  width: fit-content;

}

.gap li {
  //border: 1px solid black;
  margin: 5px;
  padding: 5px;
  background-color: $white;
  color: $text-body-color;
  //cursor: pointer; //make it look clickable
}

.gap-modal-title {
  color: $text-title-color;
}

.strat-modal-title {
  color: $text-subheading-color;
  background-color: $text-highlight-color;
}

.strat-body {
  color: $text-body-color;
  text-align: left;
  //display: flex;
  //flex-direction: row;
  //align-items: flex-start;
//  align-content: flex-start;
  //flex-wrap: wrap;
 //list-style: none;

  //width: fit-content;
}

.strat-body-back {
  margin: 5px;
  padding: 5px;
  font-size: large;
  background-color: $white;
  color: $text-body-color;
  cursor: pointer; //make it look clickable
  width: fit-content;
}

.strat-body li {
  cursor: pointer;
}


.nav-panel {

  .nav-heading {
    font-size: 1.5rem; // Adjust the size to what was previously <h4>
    font-weight: normal; // Optionally adjust the weight
    line-height: 1.2; // Line height for spacing
    margin-bottom: 1rem; // Adjust spacing around the heading
    color: #333; // Adjust the color if necessary
  }

  .nav-sub-heading {
    font-size: 1.5rem; // Adjust the size to what was previously <h4>
    font-weight: normal; // Optionally adjust the weight
    line-height: 1.2; // Line height for spacing
    margin-bottom: 1rem; // Adjust spacing around the heading
    color: #333; // Adjust the color if necessary
  }

  .nav-link {
    display: inline-block;
    padding: 10px 20px;
    margin: 5px;
    background-color: #007bff;  // Primary button color
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    border: 1px solid transparent;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;

    &:hover {
      background-color: #0056b3;  // Darken on hover
      border-color: #004085;
    }

    &:active {
      background-color: #004085;  // Even darker on click
      border-color: #002752;
    }

    &.active {
      background-color: #28a745;  // Example for active state
      border-color: #218838;
    }

    &.disabled {
      opacity: 0.5; // Make it look disabled
      pointer-events: none; // Ensure it's not clickable
      background-color: #6c757d; // Optional: A different background color for disabled state
      border-color: #6c757d;
    }

    &.placeholder {
      visibility: hidden; // Makes the placeholder invisible
      pointer-events: none; // Ensures it is non-interactive
    }
  }
}